import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Grid, useMediaQuery } from '@mui/material'
import { getDocs, query, collection, where, doc, updateDoc, addDoc, increment, Timestamp } from 'firebase/firestore'
import { fetchWithTimeout, replaceUndefinedValues } from '../helpers/functions'
import UAParser from 'ua-parser-js'
import { useTheme } from '@mui/material/styles'
import Loading from '../components/Loading'
import CompanySelection from '../modules/CompanySelection'
import { db } from '../system/firebase/index'

const PdfRedirect = ({ company, params }) => {
    const [content, setContent] = useState(<CompanySelection />)
    const [check, setCheck] = useState('initial')
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('laptop'))
    useEffect(() => {
        setCheck(desktop)
    }, [desktop])
    useEffect(() => {
        if (company){
            setContent(<Loading color='black.main' />)
        }
    }, [company])
    useEffect(() => {
        const fetchPdfData = async () => {
            const fetchGeoData = async () => {
                try {
                    const ipResponse = await fetchWithTimeout('https://api.ipify.org?format=json', { timeout: 2000 })
                    const { ip } = await ipResponse.json()
                    const geoResponse = await fetchWithTimeout(`https://ipapi.co/${ip}/json/`, { timeout: 2000 })
                    const geoData = await geoResponse.json()
                    return geoData
                } catch (error) {
                    console.error('Error fetching geo data:', error)
                    return null
                }
            }
    
            const getSystemData = () => {
                try {
                    const userAgent = navigator.userAgent
                    const system = UAParser(userAgent)
                    return system
                } catch (error) {
                    console.error('Error getting system data:', error)
                    return null
                }
            }
    
            const fetchAndProcessData = async () => {
                const [geoData, systemData] = await Promise.all([fetchGeoData(), getSystemData()])
                return {
                    geo: geoData,
                    system: systemData,
                }
            }

            const data = await fetchAndProcessData()
            const refinedData = replaceUndefinedValues(data)
            const ip = data.geo ? data.geo.ip : null;
            const userAgent = data.system ? data.system.ua : null;

            try {
                const q = query(collection(db, 'listings'), where('stp', '==', params.id))
                const docSnap = await getDocs(q)
                if (docSnap.empty) {
                    setContent('Sorry, no PDF by that STP exists.')
                } else {
                    const firstDoc = docSnap.docs[0]
                    const pdfData = firstDoc.data()
                    const url = `${process.env.GATSBY_CLOUDINARY_URL}pdf/${pdfData.pdf}`
                    await addDoc(collection(db, 'visits'), {
                        listing_id: pdfData.docId,
                        pdf_stp: pdfData.stp,
                        pdf_viewed: pdfData.name,
                        timestamp: Timestamp.now(),
                        user_ip: ip,
                        user_company: company,
                        user_agent: userAgent,
                        ...refinedData,
                    })
            
                    const listingRef = doc(db, 'listings', pdfData.docId)
                    await updateDoc(listingRef, {
                        visits: increment(1),
                    })
                    if (check){
                        window.location.href = url
                    } else {
                        setContent(
                            <Button variant="tertiary" color="primary" href={url} target="_blank" rel="noopener noreferrer">
                                Download PDF
                            </Button>
                        )
                    }
                }
            } catch (error) {
                console.error('Error fetching PDF data:', error)
                setContent('An error occurred while fetching PDF data.')
            }
        }

        if (params.id && check !=='initial' && company) {
            fetchPdfData()
        }
    }, [params.id, check, company])

    return (
        <Grid alignItems='center' container={true} justifyContent='center' sx={{ display: 'flex' }} height='100vh' width='100%'>
            <Grid item>
                {content}
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    return {
        company: state.Globals.company,
    }
}

export default connect(mapStateToProps)(PdfRedirect)
